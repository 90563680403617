<template>
  <div class="about">
    <h1>Impressum</h1>
    <p>
      Ingrid Dünnewald<br />
      Erzieherin / Begleitende Kinesiologin / Systemische Familientherapeutin<br />
      Kiefernweg 8<br />
      48346 Ostbevern
    </p>
    <p>
      Telefon: <a href="tel:+4925321778">02532 - 1778</a><br />
      Mobil: <a href="tel:+4917686961386">0176 - 869 613 86</a><br />
      E-Mail: <a href="mailto:info@balanceraeume.de">info@balanceraeume.de</a>
    </p>
    <br /><br />
    <h2>Disclaimer – rechtliche Hinweise</h2>
    <h3>§ 1 Warnhinweis zu Inhalten</h3>
    <p>
      Die kostenlosen und frei zugänglichen Inhalte dieser Webseite wurden mit
      größtmöglicher Sorgfalt erstellt. Der Anbieter dieser Webseite übernimmt
      jedoch keine Gewähr für die Richtigkeit und Aktualität der
      bereitgestellten kostenlosen und frei zugänglichen journalistischen
      Ratgeber und Nachrichten. Namentlich gekennzeichnete Beiträge geben die
      Meinung des jeweiligen Autors und nicht immer die Meinung des Anbieters
      wieder. Allein durch den Aufruf der kostenlosen und frei zugänglichen
      Inhalte kommt keinerlei Vertragsverhältnis zwischen dem Nutzer und dem
      Anbieter zustande, insoweit fehlt es am Rechtsbindungswillen des
      Anbieters.
    </p>

    <h3>§ 2 Externe Links</h3>
    <p>
      Diese Website enthält Verknüpfungen zu Websites Dritter ("externe Links").
      Diese Websites unterliegen der Haftung der jeweiligen Betreiber. Der
      Anbieter hat bei der erstmaligen Verknüpfung der externen Links die
      fremden Inhalte daraufhin überprüft, ob etwaige Rechtsverstöße bestehen.
      Zu dem Zeitpunkt waren keine Rechtsverstöße ersichtlich. Der Anbieter hat
      keinerlei Einfluss auf die aktuelle und zukünftige Gestaltung und auf die
      Inhalte der verknüpften Seiten. Das Setzen von externen Links bedeutet
      nicht, dass sich der Anbieter die hinter dem Verweis oder Link liegenden
      Inhalte zu Eigen macht. Eine ständige Kontrolle der externen Links ist für
      den Anbieter ohne konkrete Hinweise auf Rechtsverstöße nicht zumutbar. Bei
      Kenntnis von Rechtsverstößen werden jedoch derartige externe Links
      unverzüglich gelöscht.
    </p>

    <h3>§ 3 Urheber- und Leistungsschutzrechte</h3>
    <p>
      Die auf dieser Website veröffentlichten Inhalte unterliegen dem deutschen
      Urheber- und Leistungsschutzrecht. Jede vom deutschen Urheber- und
      Leistungsschutzrecht nicht zugelassene Verwertung bedarf der vorherigen
      schriftlichen Zustimmung des Anbieters oder jeweiligen Rechteinhabers.
      Dies gilt insbesondere für Vervielfältigung, Bearbeitung, Übersetzung,
      Einspeicherung, Verarbeitung bzw. Wiedergabe von Inhalten in Datenbanken
      oder anderen elektronischen Medien und Systemen. Inhalte und Rechte
      Dritter sind dabei als solche gekennzeichnet. Die unerlaubte
      Vervielfältigung oder Weitergabe einzelner Inhalte oder kompletter Seiten
      ist nicht gestattet und strafbar. Lediglich die Herstellung von Kopien und
      Downloads für den persönlichen, privaten und nicht kommerziellen Gebrauch
      ist erlaubt. Die Darstellung dieser Website in fremden Frames ist nur mit
      schriftlicher Erlaubnis zulässig.
    </p>

    <h3>§ 4 Besondere Nutzungsbedingungen</h3>
    <p>
      Soweit besondere Bedingungen für einzelne Nutzungen dieser Website von den
      vorgenannten Paragraphen abweichen, wird an entsprechender Stelle
      ausdrücklich darauf hingewiesen. In diesem Falle gelten im jeweiligen
      Einzelfall die besonderen Nutzungsbedingungen.
    </p>
    <p>
      Quelle:
      <a href="https://www.juraforum.de/impressum-generator/">zum Artikel</a>
    </p>
  </div>
</template>
